import moment from "moment";
import {
	getDienstNannyId,
	getDienstNannyName,
	getRegion,
	getSubscriptionStatusLabelComponent,
	REGIONOPTIONS,
	subscriptionStatusOptions
} from "../../constants";

import { Dropdown } from "semantic-ui-react";

import styles from "./index.module.css";

export const vastePlanningColumns = [
	{
		name: "region",
		label: "Regio",
		getSortLabel: (dienst) => dienst.region,
		getDisplayContent: (dienst, familiesById) => getRegion(dienst, familiesById),
		filter: (search, dienst, familiesById) => {
			const region = getRegion(dienst, familiesById);
			return search?.length > 0 ? search.includes(region) : true;
		},
		customFilterComponent: (search, onSearchChange) => (
			<Dropdown
				name="region"
				icon="search"
				iconPosition="left"
				placeholder="Filter..."
				value={search || []}
				onChange={onSearchChange}
				selection
				search
				multiple
				options={Object.values(REGIONOPTIONS).map((region) => ({
					key: region,
					text: region,
					value: region
				}))}
				className={styles.searchDropdown}
				fluid
				clearable
			/>
		)
	},
	{
		name: "status",
		label: "Status",
		getDisplayContent: (dienst) => getSubscriptionStatusLabelComponent(dienst.status),
		getSortLabel: (dienst) => dienst.status,
		filter: (search, dienst) => (search?.length > 0 ? search.includes(dienst.status) : true),
		customFilterComponent: (search, onSearchChange) => (
			<Dropdown
				name="status"
				icon="search"
				iconPosition="left"
				placeholder="Filter..."
				value={search || []}
				onChange={onSearchChange}
				selection
				search
				multiple
				options={subscriptionStatusOptions}
				className={styles.searchDropdown}
				fluid
				clearable
			/>
		)
	},
	{
		name: "startDate",
		label: "Startdatum",
		getSortLabel: (dienst) => dienst.startDate,
		getDisplayContent: (dienst) =>
			dienst.startDate
				? moment(dienst.startDate, ["DD-MM-YYYY", "YYYY-MM-DD"]).format("LL (dd, [W]WW)")
				: ""
	},
	{
		name: "endDate",
		label: "Einddatum",
		getSortLabel: (dienst) => dienst.endDate,
		getDisplayContent: (dienst) =>
			dienst.endDate
				? moment(dienst.endDate, ["DD-MM-YYYY", "YYYY-MM-DD"]).format("LL (dd, [W]WW)")
				: ""
	},
	{
		name: "dow",
		label: "Dag",
		getSortLabel: (dienst) => dienst.dow,
		getDisplayContent: (dienst) =>
			dienst.startDate
				? moment(dienst.startDate, ["DD-MM-YYYY", "YYYY-MM-DD"]).format("dddd")
				: ""
	},
	{
		name: "startTime",
		label: "Van",
		getDisplayContent: (dienst) => dienst.startTime
	},
	{
		name: "endTime",
		label: "Tot",
		getDisplayContent: (dienst) => dienst.endTime
	},
	{
		name: "familyId",
		label: "Klantnummer",
		getDisplayContent: (dienst) => {
			if (typeof dienst.familyId === "string") return dienst.familyId;
			console.log("INVALID FAMILY", dienst);
			return "";
		},
		filter: (searchText, dienst) => dienst.familyId === searchText.toUpperCase()
	},
	{
		name: "familyName",
		label: "Gezin",
		getDisplayContent: (dienst, familiesById) => familiesById?.[dienst.familyId]?.name || ""
	},
	{
		name: "locationkids",
		label: "Locatie & kinderen",
		getDisplayContent: (dienst, familiesById) => {
			let family = familiesById?.[dienst.familyId];

			let location = family?.location;
			let kids = family?.kids;
			if (!location) location = dienst.location;
			if (!kids) kids = dienst.kids;

			let content = "";
			if (location) content += location;
			if (kids) {
				if (content) content += " - ";
				content += kids;
			}
			return content;
		}
	},
	{
		name: "nanny",
		label: "Nanny",
		getDisplayContent: (dienst, familiesById, knownNanniesPerFamily, reactions, nanniesById) =>
			getDienstNannyName(dienst, nanniesById)
	},
	{
		name: "bekend",
		label: "Bekend",
		getDisplayContent: (dienst, familiesById, knownNanniesPerFamily) => {
			let nannyId = getDienstNannyId(dienst);
			if (!nannyId) return "";
			if (!knownNanniesPerFamily) return "";

			let isBekend = false;
			if (knownNanniesPerFamily[dienst.familyId]) {
				isBekend = knownNanniesPerFamily[dienst.familyId]
					.map((nanny) => nanny.id)
					.includes(nannyId);
			}
			return isBekend ? "Ja" : "Nee";
		}
	},
	// {
	// 	name: "creation_datetime",
	// 	label: "Aangemaakt",
	// 	getDisplayContent: (dienst) => {
	// 		return moment(dienst.creationTimestamp).format("DD-MM-YYYY HH:mm");
	// 	},
	// 	getSortLabel: (dienst) => dienst.creationTimestamp
	// },

	{
		name: "remarks",
		label: "Info",
		getDisplayContent: (dienst) => dienst?.remarks || ""
	}
];
