import React, { useState } from "react";
import moment from "moment";
import { Form, Segment, Table } from "semantic-ui-react";
import styles from "./OverviewTab.module.css";

import MonthDropdown from "../../components/MonthDropdown";

import {
	DienstSource,
	DienstStatus,
	DienstType,
	getDienstTypeLabel,
	getRegion,
	isCancelled,
	REGIONOPTIONS
} from "../../constants";
import { useActualDiensten } from "../../hooks/useActualDiensten";
import { useFamiliesById } from "../../hooks/useFamilies";

const getFlexRow = (diensten, type) => {
	let filteredDiensten = diensten;
	if (typeof type !== "undefined") {
		filteredDiensten = diensten?.filter((dienst) => dienst.type === type) || [];
	}

	let total = filteredDiensten?.length || 0;
	let confirmed =
		filteredDiensten?.filter(
			(dienst) =>
				dienst.status === DienstStatus.Confirmed ||
				dienst.status === DienstStatus.AddedByNanny
		)?.length || 0;
	let cancelled = filteredDiensten?.filter((dienst) => isCancelled(dienst.status))?.length || 0;
	let open = total - confirmed - cancelled;

	let Cell = Table.Cell;
	if (typeof type === "undefined") {
		Cell = Table.HeaderCell;
	}

	return (
		<Table.Row>
			<Cell>{typeof type === "undefined" ? "Totaal" : getDienstTypeLabel(type)}</Cell>
			<Cell collapsing>{confirmed}</Cell>
			<Cell collapsing>{cancelled}</Cell>
			<Cell collapsing>{open}</Cell>
			<Cell collapsing>
				<b>{total}</b>
			</Cell>
		</Table.Row>
	);
};

const getFixedRow = (subscriptionDiensten, dow) => {
	let diensten = subscriptionDiensten;
	if (dow > 0) {
		diensten = diensten?.filter(
			(dienst) => moment(dienst.date, "YYYY-MM-DD").isoWeekday() === dow
		);
	}

	let Cell = Table.Cell;
	if (dow < 0) {
		Cell = Table.HeaderCell;
	}

	return (
		<Table.Row key={dow}>
			<Cell>
				{dow > 0
					? moment()
							.startOf("week")
							.add(dow - 1, "days")
							.format("dd")
					: "Totaal"}{" "}
			</Cell>
			<Cell collapsing>
				{diensten?.filter((dienst) => dienst.status === DienstStatus.Open)?.length || 0}
			</Cell>
			<Cell collapsing>
				{diensten?.filter((dienst) => dienst.status === DienstStatus.LookingForReplacement)
					?.length || 0}
			</Cell>
			<Cell collapsing>
				{diensten?.filter((dienst) => dienst.status === DienstStatus.Replaced)?.length || 0}
			</Cell>
			<Cell collapsing>
				{diensten?.filter((dienst) => isCancelled(dienst.status))?.length || 0}
			</Cell>
			<Cell collapsing>
				{diensten?.filter(
					(dienst) => dienst.nannyId && dienst.status === DienstStatus.Confirmed
				)?.length || 0}
			</Cell>
			<Cell collapsing>
				<b>{diensten?.length || 0}</b>
			</Cell>
		</Table.Row>
	);
};

const ExportDaysTab = () => {
	const familiesById = useFamiliesById();
	const [month, setMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [region, setRegion] = useState("all");

	const { actualDiensten, loading: loadingActualDiensten } = useActualDiensten({
		familyId: null,
		fromMonthStr: month,
		toMonthStr: month,
		includeReplaced: true
	});

	const loading = loadingActualDiensten;

	let regionDiensten = actualDiensten;
	if (region !== "all") {
		regionDiensten =
			actualDiensten?.filter((dienst) => getRegion(dienst, familiesById) === region) || [];
	}

	const subscriptionDiensten =
		regionDiensten?.filter((dienst) => dienst.source === DienstSource.Subscription) || [];
	const flexDiensten =
		regionDiensten?.filter((dienst) => dienst.source === DienstSource.Dienst) || [];

	return (
		<div className={styles.container}>
			<div className={styles.item}>
				<Segment.Group className={styles.segmentGroup}>
					<Segment className={styles.header}>Selectie</Segment>
					<Segment className={styles.content}>
						<Form>
							<MonthDropdown label="Maand" month={month} setMonth={setMonth} />

							<Form.Field>
								<label>Regio</label>
								<Form.Group inline>
									<Form.Radio
										label="Alle regio's"
										value="all"
										checked={region === "all"}
										onChange={() => setRegion("all")}
									/>
									{Object.values(REGIONOPTIONS).map((reg) => (
										<Form.Radio
											key={reg}
											label={reg}
											value={reg}
											checked={reg === region}
											onChange={() => setRegion(reg)}
										/>
									))}
								</Form.Group>
							</Form.Field>
						</Form>
					</Segment>
				</Segment.Group>
				<Segment.Group>
					<Segment className={styles.header}>Flex diensten</Segment>
					<Segment className={styles.content} loading={loading}>
						<Table celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Type</Table.HeaderCell>
									<Table.HeaderCell collapsing>Ingevuld</Table.HeaderCell>
									<Table.HeaderCell collapsing>Geannuleerd</Table.HeaderCell>
									<Table.HeaderCell collapsing>Openstaand</Table.HeaderCell>
									<Table.HeaderCell collapsing>Totaal</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{getFlexRow(flexDiensten, DienstType.Extra)}
								{getFlexRow(flexDiensten, DienstType.LastMinute)}
								{getFlexRow(flexDiensten, DienstType.Replacement)}
							</Table.Body>
							<Table.Footer>{getFlexRow(flexDiensten)}</Table.Footer>
						</Table>
					</Segment>
				</Segment.Group>
				<Segment.Group>
					<Segment className={styles.header}>Vaste diensten</Segment>
					<Segment className={styles.content} loading={loading}>
						<Table celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Type</Table.HeaderCell>
									<Table.HeaderCell collapsing>Openstaand</Table.HeaderCell>
									<Table.HeaderCell collapsing>
										Vervanging gezocht
									</Table.HeaderCell>
									<Table.HeaderCell collapsing>Vervangen</Table.HeaderCell>
									<Table.HeaderCell collapsing>Geannuleerd</Table.HeaderCell>
									<Table.HeaderCell collapsing>Ingevuld</Table.HeaderCell>
									<Table.HeaderCell collapsing>Totaal</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{getFixedRow(subscriptionDiensten, 1)}
								{getFixedRow(subscriptionDiensten, 2)}
								{getFixedRow(subscriptionDiensten, 3)}
								{getFixedRow(subscriptionDiensten, 4)}
								{getFixedRow(subscriptionDiensten, 5)}
								{getFixedRow(subscriptionDiensten, 6)}
								{getFixedRow(subscriptionDiensten, 7)}
							</Table.Body>
							<Table.Footer>{getFixedRow(subscriptionDiensten, -1)}</Table.Footer>
						</Table>
					</Segment>
				</Segment.Group>
			</div>
		</div>
	);
};

export default ExportDaysTab;
